import React from "react";
import ContentPane from "./contentPane";
import Header from "./header";

class App extends React.Component {


  render() {
    return (
      <div className="App">
        <Header />
        <ContentPane />
      </div>
    );
  }
}

export default App;