import React, { Component } from "react";

class publishButton extends Component {



    render() {
        return (
            <button className="GeneratorButton" onClick={this.props.publish}>
                Balance ta punchline
            </button>
        );
    }
}

export default publishButton;
