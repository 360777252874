import React, { Component } from "react";

class Ragot extends Component {
  render() {
    const gradients = [
      "SatYellow",
      "SatGreen",
      "SatPurple",
      "SatPink",
      "PastelPink",
      "PastelBlue",
      "SatBlue",
    ];

    return (
      <div
        className={
          "Ragot " + gradients[Math.floor(Math.random() * gradients.length)]
        }
      >
        <span className="chunk intro">{this.props.intro}</span>&nbsp;
        <span className="chunk sujet">{this.props.sujet}</span>&nbsp;
        <span className="chunk action">{this.props.action}</span>&nbsp;
        <span className="chunk outro">{this.props.outro}</span>&nbsp;
      </div>
    );
  }
}

export default Ragot;
