import React, { Component } from "react";
import {
  NavLink,
} from "react-router-dom";

class header extends Component {
  state = {};
  render() {
    const checkActive = (match, location) => {
      //some additional logic to verify you are in the home URI
      if (!location) return false;
      const { pathname } = location;
      console.log(pathname);
      return pathname === "/";
    }
    return (
      <header>
        <NavLink className="menuitem" isActive={checkActive} to="/">
          <div className="logo">Ktatronics<span className="dot"></span></div>
        </NavLink>
        <nav>
          <NavLink className="menuitem" isActive={checkActive} to="/">Ecouter une Ktastar</NavLink>
          <NavLink className="menuitem" to="/editor">Enfumer un touriste</NavLink>
        </nav>
      </header >
    );
  }
}

export default header;
