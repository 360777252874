import React, { Component } from "react";
class RagotEditor extends Component {


  render() {
    return (
      <div className="RagotEditor">
        <span className="starter start-intro"></span>
        <span contentEditable="true" onInput={this.handleChange} className="chunk intro SatYellow">
          {this.props.intro}
        </span>
        <span className="spacer">/</span>
        <span className="starter start-sujet"></span>
        <span contentEditable="true" onInput={this.handleChange} className="chunk sujet SatGreen">
          {this.props.sujet}
        </span>
        <span className="spacer">/</span>
        <span className="starter start-action"></span>
        <span contentEditable="true" onInput={this.handleChange} className="chunk action SatPurple">
          {this.props.action}
        </span>
        <span className="spacer">/</span>
        <span className="starter start-outro"></span>
        <span contentEditable="true" onInput={this.handleChange} className="chunk outro SatPink">
          {this.props.outro}
        </span>
      </div>
    );
  }
}

export default RagotEditor;
