import React, { Component } from 'react';
import Ragot from './ragot'
import GeneratorButton from "./generatorButton"
class ragotPane extends Component {
    state = {}
    render() {
        return (
            <React.Fragment>
                <Ragot
                    intro={this.props.chunks.intro}
                    sujet={this.props.chunks.sujet}
                    action={this.props.chunks.action}
                    outro={this.props.chunks.outro}
                />
                <GeneratorButton generator={this.props.generator} />
            </React.Fragment>
        );
    }
}

export default ragotPane;