import React, { Component } from 'react';
import RagotEditor from './ragotEditor';
import PublishButton from './publishButton';
import { useHistory } from "react-router-dom";

class editorPane extends Component {
    state = {
        intro: this.props.chunks.intro,
        sujet: this.props.chunks.sujet,
        action: this.props.chunks.action,
        outro: this.props.chunks.outro,
    };

    publish() {
        this.props.publish(this.state)
    }

    update() {
        this.setState({
            intro: this._intro.textContent,
            sujet: this._sujet.textContent,
            action: this._action.textContent,
            outro: this._outro.textContent
        });
    }


    render() {
        return (
            <React.Fragment>
                <div className="RagotEditor">
                    <span className="starter start-intro"></span>
                    <span contentEditable="true" ref={c => this._intro = c} onInput={this.update.bind(this)} className="chunk intro SatYellow">
                        {this.props.chunks.intro}
                    </span>
                    <span className="spacer">/</span>
                    <span className="starter start-sujet"></span>
                    <span contentEditable="true" ref={c => this._sujet = c} onInput={this.update.bind(this)} className="chunk sujet SatGreen">
                        {this.props.chunks.sujet}
                    </span>
                    <span className="spacer">/</span>
                    <span className="starter start-action"></span>
                    <span contentEditable="true" ref={c => this._action = c} onInput={this.update.bind(this)} className="chunk action SatPurple">
                        {this.props.chunks.action}
                    </span>
                    <span className="spacer">/</span>
                    <span className="starter start-outro"></span>
                    <span contentEditable="true" ref={c => this._outro = c} onInput={this.update.bind(this)} className="chunk outro SatPink">
                        {this.props.chunks.outro}
                    </span>
                </div>
                <small>Vous avez un ragot à lacher ? Vous pouvez éditer une ou toutes les parties de la phrase et nous l'envoyer. Le sujet doit être au pluriel.</small>
                <PublishButton publish={this.publish.bind(this)} />
            </React.Fragment>
        );
    }
}

export default editorPane;