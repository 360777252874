import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import NoMatchPage from "./noMatchPage"
import EditorPane from './editorPane';
import RagotPane from './ragotPane'
import { withAlert } from 'react-alert'



class contentPane extends Component {
    state = {
        intro: "",
        sujet: "",
        action: "",
        outro: "",
    };




    componentDidMount = () => {
        this.handleRagotGenerator();
    }

    handleRagotGenerator = () => {
        fetch("/kta.php")
            .then((res) => res.json())
            .then((result) => {
                this.setState({
                    intro: result.intro,
                    sujet: result.sujet,
                    action: result.action,
                    outro: result.outro,
                });
            });
    };

    handlePublishGenerator = (chunks) => {
        alert("C'est envoyé ! ");
        fetch("/publish.php", {
            "body": JSON.stringify(chunks),
            "method": "POST",
            "mode": "cors"
        });
    };



    render() {
        return (
            <div className="content">
                <Switch>
                    <Route exact path="/"
                        render={() => <RagotPane chunks={this.state} generator={this.handleRagotGenerator} />}
                    >
                    </Route>
                    <Route path="/editor"
                        render={() => <EditorPane chunks={this.state} publish={this.handlePublishGenerator} />}
                    />
                    <Route component={NoMatchPage} />
                </Switch>
            </div>
        );
    }
}

export default contentPane;