import React, { Component } from 'react';
class NoMatchPage extends Component {
    state = {}
    render() {
        return (
            <React.Fragment>
                No results Found
            </React.Fragment>
        );
    }
}

export default NoMatchPage;