import React, { Component } from "react";

class GeneratorButton extends Component {

  render() {
    const salles = [
      "au Cabi",
      "à Byzance",
      "à Chateau",
      "à La Plage",
      "au Cellier",
      "à Cuillère",
      "à Zlard",
      "à La Poste",
      "à Philibert",
      "à la PC",
      "dans le grand nord",
      "à L'apéro",
    ];

    return (
      <button className="GeneratorButton" onClick={this.props.generator}>
        Bouger {salles[Math.floor(Math.random() * salles.length)]}
      </button>
    );
  }
}

export default GeneratorButton;
